import React, { useState, useEffect } from "react";
import '../Styles/ProgressPage.css';
import { useNavigate } from "react-router-dom";
import { useUser } from '../../../Contexts/userContext';
import BackButton from '../../../Components/BackButton/BackButton';

function ProgressPage() {
    const [goals, setGoals] = useState([]);
    const [commentsVisible, setCommentsVisible] = useState({});
    const [buttonText, setButtonText] = useState({});
    const [comments, setComments] = useState({});
    const [currentComment, setCurrentComment] = useState({});
    const [goalTextVisible, setGoalTextVisible] = useState([]);
    const [goalComments, setGoalComments] = useState({});

    const navigate = useNavigate();
    const { userId, firstName, lastName } = useUser();

    useEffect(() => {
        const fetchGoalsAndComments = async () => {
            try {
                const response = await fetch(`/api/goals/get-goals/${userId}`);
                const data = await response.json();
                console.log("Fetched Goals:", data);
                setGoals(data);
                setCommentsVisible(data.reduce((acc, _, index) => ({ ...acc, [index]: false }), {}));
                setButtonText(Array(data.length).fill("Add Comment"));
                setCurrentComment(data.reduce((acc, _, index) => ({ ...acc, [index]: "" }), {}));
            } catch (error) {
                console.error("Error fetching goals or comments:", error);
            }
        };

        if (userId) {
            fetchGoalsAndComments();
        }
    }, [userId]);

    const handleProgressChange = (index, newProgress) => {
        const updatedGoals = [...goals];
        updatedGoals[index].progress = newProgress;
        setGoals(updatedGoals);
    };

    const handleCommentToggle = (index) => {
        if (buttonText[index] === "Confirm") {
            // Make the comment read-only
            handleCommentConfirm(index);
        } else if (buttonText[index] === "Edit") {
            // Make the comment editable again
            setCommentsVisible((prev) => ({
                ...prev,
                [index]: true,
            }));
            setButtonText((prev) => ({
                ...prev,
                [index]: "Confirm",
            }));
        } else {
            // Initial "Add Comment" click
            setCommentsVisible((prev) => ({
                ...prev,
                [index]: true,
            }));
            setButtonText((prev) => ({
                ...prev,
                [index]: "Confirm",
            }));
        }
    };

    const handleCommentConfirm = (index) => {
        const newComment = currentComment[index];
        if (!newComment || newComment.trim() === "") {
            // Clear both current and saved comments when confirming empty text
            setCommentsVisible((prev) => ({
                ...prev,
                [index]: false,
            }));
            setButtonText((prev) => ({
                ...prev,
                [index]: "Add Comment",
            }));
            setCurrentComment((prev) => ({
                ...prev,
                [index]: "",
            }));
            setComments((prev) => ({
                ...prev,
                [index]: "",
            }));
            return;
        }

        // Keep the comment in both states to maintain editability
        setComments((prev) => ({
            ...prev,
            [index]: newComment,
        }));

        setButtonText((prev) => ({
            ...prev,
            [index]: "Edit",
        }));
    };

    const handleSubmit = async () => {
        // Prepare comments with signature, filtering out empty comments
        const commentsToSubmit = Object.entries(comments)
            .filter(([_, comment]) => comment && comment.trim() !== "") // Filter out empty or whitespace-only comments
            .map(([index, comment]) => ({
                goalId: goals[index].id,
                comment: `${comment} - ${firstName} ${lastName}`,
                userId,
            }));

        // Only proceed if there are comments to submit
        if (commentsToSubmit.length === 0) {
            console.log('No comments to submit');
            handleGoBack();
            return;
        }

        // Save comments to the database
        try {
            await Promise.all(commentsToSubmit.map(comment => {
                return fetch('/api/comments/add-comment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(comment),
                });
            }));
            console.log('Comments submitted successfully');
            handleGoBack();
        } catch (error) {
            console.error('Error submitting comments:', error);
        }
    };

    const handleGoBack = () => {
        window.scrollTo(0, 0);
        navigate(-1);
    };

    const handleGoalTextToggle = (index) => {
        setGoalTextVisible((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };

    const handleShowComments = async (goalId) => {
        if (!commentsVisible[goalId]) {
            // Only fetch comments if they are not already visible
            try {
                const response = await fetch(`/api/comments/get-comments/${goalId}`);
                const data = await response.json();
                setGoalComments((prev) => ({
                    ...prev,
                    [goalId]: data,
                }));
            } catch (error) {
                console.error('Error fetching all comments:', error);
            }
        }
        // Toggle visibility
        setCommentsVisible((prev) => ({
            ...prev,
            [goalId]: !prev[goalId],
        }));
    };

    return (
        <div className="progress-page-body">
            <div className="progress-page-back-wrapper">
                <BackButton onClick={handleGoBack} />
            </div>
            <div className="progress-page-content">
                <header className="progress-page-header">
                    <h2 className="progress-page-header-text">Goal Progress</h2>
                </header>
                <div className="progress-page-goals">
                    {goals.map((goal, index) => (
                        <div key={goal.id}>
                            <h5 className="progress-page-goal-name">{goal.summary || goal.goal_text}</h5>
                            {goalTextVisible[index] && (
                                <p className="progress-page-goal-text">{goal.goal_text}</p>
                            )}
                            <button 
                                className="progress-page-goal-text-toggle-button" 
                                onClick={() => handleGoalTextToggle(index)}
                            >
                                {goalTextVisible[index] ? "Show Less" : "Show More"}
                            </button>
                            <div className="progress-page-goal-progress-container">
                                <div className="progress-page-goal-progress-percentage-container">
                                    <input
                                        className="progress-page-goal-progress"
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={goal.progress}
                                        onChange={(e) => handleProgressChange(index, e.target.value)}
                                    />
                                    <span className="progress-page-goal-progress-percentage">{goal.progress}%</span>
                                </div>
                                <button 
                                    className="progress-page-goal-comment-button" 
                                    onClick={() => handleCommentToggle(index)}
                                >
                                    {buttonText[index] || "Add Comment"}
                                </button>
                            </div>
                            {/* Single editable/readonly textarea per goal */}
                            {(commentsVisible[index] || comments[index]) && (
                                <div className="progress-page-goal-comment-container">
                                    <textarea
                                        className="progress-page-goal-comment"
                                        placeholder="Add your comment here..."
                                        value={buttonText[index] === "Confirm" 
                                            ? (currentComment[index] || "") 
                                            : (comments[index] || "")}
                                        onChange={(e) => {
                                            if (buttonText[index] === "Confirm") {
                                                setCurrentComment((prev) => ({
                                                    ...prev,
                                                    [index]: e.target.value,
                                                }));
                                            }
                                        }}
                                        readOnly={buttonText[index] === "Edit"}
                                    />
                                </div>
                            )}
                            {/* Display area for previously submitted comments */}
                            <div className="progress-page-goal-comments-list">
                                {commentsVisible[goal.id] && (
                                    <ul>
                                        {goalComments[goal.id] && goalComments[goal.id]
                                            .filter(comment => comment && comment.comment)
                                            .map((comment, idx) => (
                                                <li key={idx} className="progress-page-goal-comment-item">
                                                    <p>{comment.comment}</p>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>
                            <button 
                                className="progress-page-goal-text-toggle-button" 
                                onClick={() => handleShowComments(goal.id)}
                            >
                                {commentsVisible[goal.id] ? "Hide Comments" : "Show Comments"}
                            </button>
                        </div>
                    ))}
                </div>
                <div className="progress-page-submit-container">
                    <button className="progress-page-submit-link" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ProgressPage;
