import React, { useState } from 'react';
import '../AIButton/AIButton.css'; // Reuse the same styling

function TeamAIButton({ userId, onNewGoal }) {
    const [loading, setLoading] = useState(false);

    const handleGenerateTeamGoal = async () => {
        setLoading(true);
        try {
            const response = await fetch('/api/ai-team-goals/generate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                body: JSON.stringify({ managerId: userId })
            });
            
            if (!response.ok) {
                throw new Error('Failed to generate team goal');
            }
            
            const data = await response.json();
            onNewGoal(data.goal);
        } catch (error) {
            console.error('Error generating team goal:', error);
            alert('Failed to generate team goal. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <button 
            onClick={handleGenerateTeamGoal} 
            disabled={loading} 
            className='astellyst-ai-button'
        >
            {loading ? 'Generating...' : 'Astellyst AI'}
        </button>
    );
}

export default TeamAIButton;
