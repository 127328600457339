import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../../Contexts/userContext';
import '../Styles/ManagerProgress.css';

const ManagerProgress = () => {
    const { userId } = useParams(); // Employee's userId
    const { userId: commenterId, firstName, lastName, userRole } = useUser(); // Use userId from context as managerId
    const managerName = `${firstName} ${lastName}`; // Construct manager's name
    const navigate = useNavigate(); // Initialize navigate
    const [employeeName, setEmployeeName] = useState(""); // Add state for employee's name


    const [goals, setGoals] = useState([]);
    const [comments, setComments] = useState({}); // Change to object structure
    const [newComment, setNewComment] = useState("");
    const [goalTextVisible, setGoalTextVisible] = useState([]); // Add state for goal text visibility
    const [commentsVisible, setCommentsVisible] = useState({}); // Use an object to track visibility of comments for each goal
    const [buttonText, setButtonText] = useState({}); // Use an object to track button text for each goal
    const [currentComment, setCurrentComment] = useState({}); // Use an object to track current comment for each goal
    const [goalComments, setGoalComments] = useState({}); // Use an object to store comments for each goal


    useEffect(() => {
        console.log("User ID from URL:", userId); // Debugging: Log userId

        const fetchEmployeeDetails = async () => {
            try {
                const response = await fetch(`/api/users/get-employee-details/${userId}`); // Use userId from URL
                const data = await response.json();
                console.log("Fetched data:", data); // Debugging: Log fetched data

                if (data.goals) {
                    setGoals(data.goals); // Set goals from the fetched data
                    setComments(Array(data.goals.length).fill("")); // Initialize comments array
                    setCommentsVisible(Array(data.goals.length).fill(false)); // Initialize comments visibility
                    setButtonText(Array(data.goals.length).fill("Add Comment")); // Initialize button text
                    setCurrentComment(Array(data.goals.length).fill("")); // Initialize current comment
                    setGoalTextVisible(Array(data.goals.length).fill(false)); // Initialize goal text visibility
                    if (data.user) {
                        setEmployeeName(`${data.user.first_name} ${data.user.last_name}`); // Set employee's name
                    }
                } else {
                    console.error("No goals found for this employee.");
                }
            } catch (error) {
                console.error("Error fetching goals:", error);
            }
        };

        if (userId) {
            fetchEmployeeDetails();
        }
    }, [userId]);

    const handleCommentToggle = (index) => {
        if (buttonText[index] === "Confirm") {
            // Make the comment read-only
            handleCommentConfirm(index);
        } else if (buttonText[index] === "Edit") {
            // Make the comment editable again
            setCommentsVisible((prev) => ({
                ...prev,
                [index]: true,
            }));
            setButtonText((prev) => ({
                ...prev,
                [index]: "Confirm",
            }));
        } else {
            // Initial "Add Comment" click
            setCommentsVisible((prev) => ({
                ...prev,
                [index]: true,
            }));
            setButtonText((prev) => ({
                ...prev,
                [index]: "Confirm",
            }));
        }
    };

    const handleCommentConfirm = (index) => {
        const newComment = currentComment[index];
        if (!newComment || newComment.trim() === "") {
            // Clear both current and saved comments when confirming empty text
            setCommentsVisible((prev) => ({
                ...prev,
                [index]: false,
            }));
            setButtonText((prev) => ({
                ...prev,
                [index]: "Add Comment",
            }));
            setCurrentComment((prev) => ({
                ...prev,
                [index]: "",
            }));
            setComments((prev) => ({
                ...prev,
                [index]: "",
            }));
            return;
        }

        // Keep the comment in both states to maintain editability
        setComments((prev) => ({
            ...prev,
            [index]: newComment,
        }));

        setButtonText((prev) => ({
            ...prev,
            [index]: "Edit",
        }));
    };

    const handleGoalTextToggle = (index) => {
        setGoalTextVisible((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };

    const handleSubmit = async () => {
        // Prepare comments with manager's signature, filtering out empty comments
        const commentsToSubmit = Object.entries(comments)
            .filter(([_, comment]) => comment && comment.trim() !== "") // Filter out empty or whitespace-only comments
            .map(([index, comment]) => ({
                goalId: goals[index].id,
                commenterId,
                commentText: `${comment} - ${managerName}`,
            }));

        // Only proceed if there are comments to submit
        if (commentsToSubmit.length === 0) {
            console.log('No comments to submit');
            handleRedirectToPortal();
            return;
        }

        // Save comments to the database
        try {
            await fetch('/api/manager-comments/submit-comments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ comments: commentsToSubmit }),
            });

            console.log('Comments submitted successfully');
            handleRedirectToPortal();
        } catch (error) {
            console.error('Error submitting comments:', error);
        }
    };

    const handleRedirectToPortal = () => {
        window.scrollTo(0, 0);
        if (userRole === 'Admin') {
            navigate('/adminportal?tab=your-team');
        } else if (userRole === 'Manager') {
            navigate('/managerportal?tab=your-team');
        } else {
            console.error('User role not recognized for redirect');
        }

    };

    const handleBackLinkClick = () => {
        navigate('/teammembers'); // Navigate to /teammembers
    };

    const handleShowComments = async (goalId) => {
        if (!commentsVisible[goalId]) {
            try {
                const response = await fetch(`/api/comments/get-comments/${goalId}`);
                const data = await response.json();
                setGoalComments((prev) => ({
                    ...prev,
                    [goalId]: data,
                }));
            } catch (error) {
                console.error('Error fetching all comments:', error);
            }
        }
        // Toggle visibility
        setCommentsVisible((prev) => ({
            ...prev,
            [goalId]: !prev[goalId],
        }));
    };

    return (
        <div className="manager-progress-body">
            <p onClick={handleBackLinkClick} className="back-link">
                Back
            </p>
            <header className="manager-progress-header">
                <h2 className="manager-progress-header-text">Goals Progress</h2>
            </header>
            <p className="manager-progress-header-subtext">Goals for {employeeName}</p>
            <div className="manager-progress-goals">

                {goals.length > 0 ? (
                    goals.map((goal, index) => (
                        <div key={goal.id}>
                            <h5 className="manager-progress-goal-name">{goal.summary || goal.goal_text}</h5>
                            {goalTextVisible[index] && <p className="manager-progress-goal-text">{goal.goal_text}</p>}
                            
                            <button 
                                className="manager-progress-goal-text-toggle-button" 
                                onClick={() => handleGoalTextToggle(index)}
                            >
                                {goalTextVisible[index] ? "Show Less" : "Show More"}
                            </button>
                            <div className="manager-progress-goal-progress-container">
                                <div className="manager-progress-goal-progress-percentage-container">
                                    <p>Progress: {goal.progress}%</p>
                                </div>
                                <button 
                                    className="manager-progress-goal-comment-button" 
                                    onClick={() => handleCommentToggle(index)}
                                >
                                    {buttonText[index] || "Add Comment"}
                                </button>
                            </div>

                            {/* Single editable/readonly textarea per goal */}
                            {(commentsVisible[index] || comments[index]) && (
                                <div className="manager-progress-goal-comment-container">
                                    <textarea
                                        className="manager-progress-goal-comment"
                                        placeholder="Add your comment here..."
                                        value={buttonText[index] === "Confirm" 
                                            ? (currentComment[index] || "") 
                                            : (comments[index] || "")}
                                        onChange={(e) => {
                                            if (buttonText[index] === "Confirm") {
                                                setCurrentComment((prev) => ({
                                                    ...prev,
                                                    [index]: e.target.value,
                                                }));
                                            }
                                        }}
                                        readOnly={buttonText[index] === "Edit"}
                                    />
                                </div>
                            )}

                            {/* Display area for previously submitted comments */}
                            <div className="manager-progress-goal-comments-list">
                                {commentsVisible[goal.id] && (
                                    <ul>
                                        {goalComments[goal.id] && goalComments[goal.id]
                                            .filter(comment => comment && comment.comment)
                                            .map((comment, idx) => (
                                                <li key={idx} className="manager-progress-goal-comment-item">
                                                    <p>{comment.comment}</p>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>
                            <button 
                                className="manager-progress-goal-text-toggle-button" 
                                onClick={() => handleShowComments(goal.id)}
                            >
                                {commentsVisible[goal.id] ? "Hide Comments" : "Show Comments"}
                            </button>
                        </div>
                    ))
                ) : (
                    <p>No goals available for this employee.</p>
                )}
            </div>
            <div className="manager-progress-submit-container">
                <button 
                    className="manager-progress-submit-link" 
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default ManagerProgress;
