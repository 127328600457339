import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/EditEmployee.css';

const EditEmployee = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        job_title: '',
        job_description: ''
    });

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const response = await fetch(`/api/users/get-employee-details/${userId}`);
                const data = await response.json();
                setFormData(data.user);
            } catch (error) {
                console.error('Error fetching employee details:', error);
            }
        };

        fetchEmployeeDetails();
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`/api/users/update-employee/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                navigate(`/EmployeeProfile/${userId}`);
            } else {
                console.error('Failed to update employee details');
            }
        } catch (error) {
            console.error('Error updating employee details:', error);
        }
    };

    const handleArchive = async () => {
        const confirmArchive = window.confirm("Are you sure you want to archive this employee?");
        if (!confirmArchive) {
            return;
        }

        try {
            const response = await fetch(`/api/users/archive-employee/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ is_archived: true })
            });
            if (response.ok) {
                navigate(`/EmployeeDirectory`);
            } else {
                console.error('Failed to archive employee');
            }
        } catch (error) {
            console.error('Error archiving employee:', error);
        }
    };

    return (
        <div className="edit-employee-container">
            <h1 className="edit-employee-header">Edit Employee</h1>

            <form className="edit-employee-form" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="first_name">First Name:</label>
                    <input type="text" id="first_name" name="first_name" value={formData.first_name} onChange={handleChange} placeholder="First Name" />
                </div>
                <div>
                    <label htmlFor="last_name">Last Name:</label>
                    <input type="text" id="last_name" name="last_name" value={formData.last_name} onChange={handleChange} placeholder="Last Name" />
                </div>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                </div>
                <div>
                    <label htmlFor="job_title">Job Title:</label>
                    <input type="text" id="job_title" name="job_title" value={formData.job_title} onChange={handleChange} placeholder="Job Title" />
                </div>
                <div>
                    <label htmlFor="job_description">Job Description:</label>
                    <textarea id="job_description" name="job_description" value={formData.job_description} onChange={handleChange} placeholder="Job Description" />
                </div>
                <button type="submit">Save</button>
            </form>
            <button className="edit-employee-delete-button" onClick={handleArchive}>Archive</button>
        </div>

    );
};

export default EditEmployee;
