import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// Import UserProvider and TabProvider
import { UserProvider } from './Contexts/userContext';
import { TabProvider } from './Contexts/tabContext';

// Pages
import Home from "./Pages/HomePage/home";
import About from "./Pages/About/about";
import Contact from "./Pages/Contact/Contact";
import Resources from "./Pages/Resources/Resources";
import SupportPage from "./Pages/Support/Support";
import CareersPage from "./Pages/Careers/Careers";
import TermsOfUse from "./Pages/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import SalesQuotePage from "./Pages/SalesQuote/SalesQuotePage";
import LogInPage from "./Pages/LogInSelection/LogInPage";
import ForgotPassword from "./Pages/LogInSelection/ForgotPassword";
import AdminPortal from "./Pages/AdminPortal/AdminPortalPage/AdminPortal";
import AdminMaster from "./Pages/AdminPortal/AdminGoalSet/AdminMaster";
import EmployeeDirectory from "./Pages/AdminPortal/AdminPortalPage/EmployeeDirectory";
import Billing from "./Pages/AdminPortal/AdminPortalPage/Billing";
import ManagerPortal from "./Pages/ManagerPortal/ManagerPortalPage/ManagerPortal";
import ManagerMaster from "./Pages/ManagerPortal/ManagerGoalSet/ManagerMaster";
import TeamMembers from "./Pages/ManagerPortal/ManagerPortalPage/TeamMembers";
import EmployeePortal from "./Pages/EmployeePortal/EmployeePortalPage/EmployeePortal";
import EmployeeMaster from "./Pages/EmployeePortal/EmployeeGoalSet/EmployeeMaster";
import ProgressPage from './Pages/EmployeePortal/EmployeeProgress/ProgressPage';
import EmployeeProfile from "./Pages/AdminPortal/AdminPortalPage/EmployeeProfile";
import EditEmployee from "./Pages/AdminPortal/AdminPortalPage/EditEmployee";
import ManagerProgress from "./Pages/ManagerPortal/ManagerPortalPage/ManagerProgress";

// Components
import WithNav from "./Components/NavFunctionality/WithNav";
import WithoutNav from "./Components/NavFunctionality/WithoutNav";
import Footer from "./Components/Footer/Footer";
import ProtectedRoute from './Components/ProtectedRoute';

function Layout({ children }) {
  return (
    <>
      {children}
      <Footer />
    </>
  );
}

function App() {
  return (
    <UserProvider>
      <Router>
        <Layout>
          <div className="content">
            <Routes>
              <Route element={<WithoutNav />}>
                <Route exact path="/" element={<Home />} />
              </Route>
              <Route element={<WithNav />}>
                <Route exact path="/About" element={<About />} />
                <Route exact path="/Contact" element={<Contact />} />
                <Route exact path="/Resources" element={<Resources />} />
                <Route exact path="/Support" element={<SupportPage />} />
                <Route exact path="/Careers" element={<CareersPage />} />
                <Route exact path="/TermsOfUse" element={<TermsOfUse />} />
                <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route exact path="/SalesQuotePage" element={<SalesQuotePage />} />
                <Route exact path="/Login" element={<LogInPage />} />
                <Route exact path="/ForgotPassword" element={<ForgotPassword />} />

                {/* Admin Portal */}
                <Route exact path="/AdminPortal" element={
                  <ProtectedRoute requiredRoles={['Admin']}>
                    <TabProvider>
                      <AdminPortal />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/AdminMaster" element={
                  <ProtectedRoute requiredRoles={['Admin']}>
                    <TabProvider>
                      <AdminMaster />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/EmployeeDirectory" element={
                  <ProtectedRoute requiredRoles={['Admin']}>
                    <TabProvider>
                      <EmployeeDirectory />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/Billing" element={
                  <ProtectedRoute requiredRoles={['Admin']}>
                    <TabProvider>
                      <Billing />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/ManagerPortal" element={
                  <ProtectedRoute requiredRoles={['Manager', 'Admin']}>
                    <TabProvider>
                      <ManagerPortal />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/ManagerMaster" element={
                  <ProtectedRoute requiredRoles={['Manager', 'Admin']}>
                    <TabProvider>
                      <ManagerMaster />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/TeamMembers" element={
                  <ProtectedRoute requiredRoles={['Manager', 'Admin']}>
                    <TabProvider>
                      <TeamMembers />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/EmployeePortal" element={
                  <ProtectedRoute requiredRoles={['Employee', 'Admin', 'Manager']}>
                    <TabProvider>
                      <EmployeePortal />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/EmployeeMaster" element={
                  <ProtectedRoute requiredRoles={['Employee', 'Admin', 'Manager']}>
                    <TabProvider>
                      <EmployeeMaster />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/ProgressPage" element={
                  <ProtectedRoute requiredRoles={['Employee', 'Admin', 'Manager']}>
                    <TabProvider>
                      <ProgressPage />
                    </TabProvider>
                  </ProtectedRoute>
                } />
                <Route exact path="/EmployeeProfile/:userId" element={
                  <ProtectedRoute requiredRoles={['Admin']}>
                    <EmployeeProfile />
                  </ProtectedRoute>
                } />
                <Route exact path="/EditEmployee/:userId" element={
                  <ProtectedRoute requiredRoles={['Admin']}>
                    <EditEmployee />
                  </ProtectedRoute>
                } />
                <Route exact path="/ManagerProgress/:userId" element={
                  <ProtectedRoute requiredRoles={['Manager', 'Admin']}>
                    <ManagerProgress />
                  </ProtectedRoute>
                } />
              </Route>
            </Routes>
          </div>
        </Layout>
      </Router>
    </UserProvider>
  );
}

export default App;
