import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState(Cookies.get('userId'));
    const [userRole, setUserRole] = useState(Cookies.get('userRole'));
    const [companyId, setCompanyId] = useState(Cookies.get('company_id'));
    const [firstName, setFirstName] = useState(Cookies.get('first_name') || '');
    const [lastName, setLastName] = useState(Cookies.get('last_name') || '');

    useEffect(() => {
        if (userId) {
            Cookies.set('userId', userId, { secure: true, sameSite: 'Lax' });
        } else {
            Cookies.remove('userId');
        }
    }, [userId]);

    useEffect(() => {
        if (userRole) {
            Cookies.set('userRole', userRole, { secure: true, sameSite: 'Lax' });
        } else {
            Cookies.remove('userRole');
        }
    }, [userRole]);

    useEffect(() => {
        console.log('Initial companyId from cookies:', companyId);
        if (companyId) {
            Cookies.set('company_id', companyId, { secure: true, sameSite: 'Lax' });
        } else {
            Cookies.remove('company_id');
        }
    }, [companyId]);

    useEffect(() => {
        if (firstName) {
            Cookies.set('first_name', firstName, { secure: true, sameSite: 'Lax' });
        } else {
            Cookies.remove('first_name');
        }
    }, [firstName]);

    useEffect(() => {
        if (lastName) {
            Cookies.set('last_name', lastName, { secure: true, sameSite: 'Lax' });
        } else {
            Cookies.remove('last_name');
        }
    }, [lastName]);

    return (
        <UserContext.Provider value={{ userId, setUserId, userRole, setUserRole, companyId, setCompanyId, firstName, setFirstName, lastName, setLastName }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
