import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTab } from '../../../Contexts/tabContext';
import BackButton from '../../../Components/BackButton/BackButton';

function ManagerStep1({ companyMission, companyGoals, nextStep, userRole }) {
    const navigate = useNavigate();
    const { activeTab } = useTab();

    const handleBackClick = () => {
        let portalPath = '/employeeportal';

        if (userRole === 'Admin') {
            portalPath = '/adminportal?tab=set-team-goals';
        } else if (userRole === 'Manager') {
            portalPath = '/managerportal?tab=set-goals';
        }
        window.scrollTo(0, 0);
        navigate(`${portalPath}`);
    };

    return (
        <>
            <div className="manager-goal-set-back-wrapper">
                <BackButton onClick={handleBackClick} />
            </div>
            <div className='manager-goal-set-container'>
                <div className="manager-goal-set-content">
                    <h2 className='manager-goal-review-title'>Review Company Mission and Goals</h2>
                    <h3 className='manager-goal-review-subtitle'>Company Mission:</h3>
                    <p className='manager-goal-review-text'>Try to incorporate the company mission into the goals you set for your team.</p>
                    <p className='manager-goal-review-mission'>{companyMission}</p>
                    <h3 className='manager-goal-review-subtitle'>Company Goals:</h3>
                    <p className='manager-goal-review-text'>These are goals your company is working towards. How can your team help achieve these goals?</p>
                    <ul className='manager-goal-review-goal-list'>
                        {companyGoals.map((goal, index) => (
                            <li key={index} className='manager-goal-set-list-item'>
                                {goal.text}
                            </li>
                        ))}
                    </ul>
                    <div className='manager-goal-set-button-container'>
                        <button className='manager-goal-set-link' onClick={nextStep}>Next</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManagerStep1;
