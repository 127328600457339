import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';

function ManagerStep3({ teamGoals, prevStep, saveForLater, setTeamGoals, submitData, userRole }) {
    const [isEditingGoals, setIsEditingGoals] = useState(false);
    const [editedGoals, setEditedGoals] = useState(teamGoals.map(goal => goal.goal_text || ''));
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setEditedGoals(teamGoals.map(goal => goal.goal_text || ''));
    }, [teamGoals]);

    const toggleEditGoals = () => {
        if (isEditingGoals) {
            const nonEmptyGoals = editedGoals
                .filter(goalText => goalText.trim() !== '')
                .map((goalText, index) => ({
                    ...teamGoals[index],
                    goal_text: goalText,
                    confirmed: true
                }));
            setTeamGoals(nonEmptyGoals);
        }
        setIsEditingGoals(!isEditingGoals);
    };

    const handleGoalChange = (index, value) => {
        const newGoals = [...editedGoals];
        newGoals[index] = value;
        setEditedGoals(newGoals);
    };

    const handleSubmit = async () => {
        const nonEmptyGoals = editedGoals
            .filter(goalText => goalText && goalText.trim() !== '')
            .map((goalText, index) => ({
                ...teamGoals[index],
                goal_text: goalText,
                confirmed: true
            }));

        const hasNullGoalText = nonEmptyGoals.some(goal => !goal.goal_text || goal.goal_text.trim() === '');
        if (hasNullGoalText) {
            alert('All goals must have text.');
            return;
        }

        setIsSubmitting(true);

        try {
            const summarizedGoals = await Promise.all(nonEmptyGoals.map(async (goal) => {
                const response = await fetch('/api/summarization/summarize-goal', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ text: goal.goal_text })
                });
                const { summary } = await response.json();
                return {
                    ...goal,
                    summary: summary || goal.goal_text.substring(0, 100) + '...'
                };
            }));

            if (summarizedGoals.length === 0) {
                alert('You must submit a goal to continue');
                return;
            }

            submitData(summarizedGoals);
            alert('Data submitted successfully!');

            if (userRole === 'Admin') {
                window.scrollTo(0, 0);
                navigate('/adminportal');
            } else if (userRole === 'Manager') {
                window.scrollTo(0, 0);
                navigate('/managerportal');
            } else {
                window.scrollTo(0, 0);
                navigate('/employeeportal');
            }
        } catch (error) {
            console.error('Error summarizing goals:', error);
            alert('There was an error summarizing your goals. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isSubmitting) {
        return (
            <div className='manager-goal-set-container'>
                <div className='loading-overlay'>
                    <div className='loading-content'>
                        <PulseLoader
                            color="#6908C9"
                            size={15}
                            margin={5}
                            speedMultiplier={0.8}
                        />
                        <h2 className='loading-text'>Summarizing and submitting goals...</h2>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='manager-goal-set-container'>
            <h2>Review Your Team Goals</h2>
            <div className='manager-goal-set-review-container'>
                <h3>Team Goals</h3>
                <div className='manager-goal-set-flex-container'>
                    {isEditingGoals ? (
                        <ul>
                            {editedGoals.map((goalText, index) => (
                                <li key={index} className='manager-goal-set-review-list-edit'>
                                    <textarea 
                                        className='manager-goal-set-input'
                                        value={goalText} 
                                        onChange={(e) => handleGoalChange(index, e.target.value)} 
                                    />
                                </li>
                            ))}
                            <div className='manager-goal-set-button-review-wrapper'>
                                <button onClick={toggleEditGoals} className='manager-goal-set-button'>Done Editing</button>
                            </div>
                        </ul>
                    ) : (
                        <ul className='manager-goal-set-review-list-text'>
                            {teamGoals.length > 0 ? (
                                teamGoals.map((goal, index) => (
                                    <li className='manager-goal-set-list-item' key={index}>{goal.goal_text}</li>
                                ))
                            ) : (
                                <p className='manager-goal-set-review-text'>No team goals provided.</p>
                            )}
                        </ul>
                    )}
                </div>
            </div>
            {!isEditingGoals && (
                <button onClick={toggleEditGoals} className='manager-goal-set-button'>Edit</button>
            )}
            <div className='manager-goal-set-button-container'>
                <button onClick={prevStep} className='manager-goal-set-link'>Back</button>
                <button onClick={saveForLater} className='manager-goal-set-link'>Save for Later</button>
                <button onClick={handleSubmit} className='manager-goal-set-submit-link'>Submit</button>
            </div>
        </div>
    );
}

export default ManagerStep3;