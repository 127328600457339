import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Import js-cookie for cookie management
import { useUser } from '../../Contexts/userContext'; // Import the User Context
import "./Navbar.css";

const Navbar = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const sidebarRef = useRef(null); // Create a ref for the sidebar
	const { userRole, userId, setUserRole, setUserId, setCompanyId } = useUser(); // Get user role and ID from context
	const isAuthenticated = !!userId; // Check if user is authenticated based on userId
	const navigate = useNavigate();

	const handleScrollToTop = () => {
		window.scrollTo(0, 0);
		setIsSidebarOpen(false);
	};

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	const handleSignOut = () => {
		// Update the context state before removing cookies
		setUserId(null);
		setUserRole(null);
		setCompanyId(null);

		// Remove cookies
		Cookies.remove('authToken');
		Cookies.remove('userRole');
		Cookies.remove('userId');
		Cookies.remove('company_id');
		Cookies.remove('first_name');
		Cookies.remove('last_name');

		// Redirect to login page
		navigate('/login');
	};

	const handleGoToPortal = () => {
		if (userRole === 'Admin') {
			navigate('/adminPortal');
		} else if (userRole === 'Manager') {
			navigate('/managerPortal');
		} else {
			navigate('/employeePortal');
		}
	};

	const handleClickOutside = (event) => {
		if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
			setIsSidebarOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="navbar-container">
			<div className="navbar">
				<div className="navbar-hamburger" onClick={toggleSidebar}>
					<div className="navbar-hamburger-line"></div>
					<div className="navbar-hamburger-line"></div>
					<div className="navbar-hamburger-line"></div>
				</div>
				<NavLink to="/" className="navbar-link-logo" onClick={handleScrollToTop}>Astellyst</NavLink>
				<div className="navbar-links">
					<NavLink to="/About" className="navbar-link" onClick={handleScrollToTop}>About</NavLink>
					<NavLink to="/Contact" className="navbar-link" onClick={handleScrollToTop}>Contact</NavLink>
					<NavLink to="/Resources" className="navbar-link" onClick={handleScrollToTop}>Resources</NavLink>
				</div>
				<div className="navbar-buttons">
					{isAuthenticated ? (
						<>
							<button className="navbar-link-button" onClick={handleGoToPortal}>Go to Portal</button>
							<button className="navbar-link-button" onClick={handleSignOut}>Sign Out</button>
						</>
					) : (
						<NavLink to="/login" className="navbar-link" onClick={handleScrollToTop}>Sign In</NavLink>
					)}
				</div>
			</div>
			{/* Sidebar */}
			<div ref={sidebarRef} className={`navbar-sidebar ${isSidebarOpen ? 'open' : ''}`}>
				<button className="navbar-close-btn" onClick={toggleSidebar}>×</button>
				<NavLink to="/About" className="navbar-sidebar-link" onClick={() => { handleScrollToTop(); toggleSidebar(); }}>About</NavLink>
				<NavLink to="/Contact" className="navbar-sidebar-link" onClick={() => { handleScrollToTop(); toggleSidebar(); }}>Contact</NavLink>
				<NavLink to="/Resources" className="navbar-sidebar-link" onClick={() => { handleScrollToTop(); toggleSidebar(); }}>Resources</NavLink>
				{isAuthenticated ? (
					<>
						<button className="navbar-sidebar-link-button" onClick={handleGoToPortal}>Go to Portal</button>
						<button className="navbar-sidebar-link-button" onClick={handleSignOut}>Sign Out</button>
					</>
				) : (
					<NavLink to="/login" className="navbar-sidebar-link" onClick={() => { handleScrollToTop(); toggleSidebar(); }}>Sign In</NavLink>
				)}
			</div>
		</div>
	);
};

export default Navbar;
