import React from 'react';
import './BackButton.css';

const BackButton = ({ onClick, className = '' }) => {
    return (
        <div className={`back-button ${className}`} onClick={onClick}>
            <span className="back-arrow">←</span>
            <span className="back-text">Back</span>
        </div>
    );
};

export default BackButton;
