// src/Pages/ManagerPortal/TeamMembers.js
import React, { useState, useEffect } from 'react';
import '../Styles/TeamMembers.css';
import { useUser } from '../../../Contexts/userContext'; // Import the useUser hook
import { Link, useNavigate } from 'react-router-dom'; // Import Link and useNavigate
import BackButton from '../../../Components/BackButton/BackButton';

const TeamMembers = () => {
    const { userId, userRole } = useUser(); // Get the userId and userRole from the context
    const navigate = useNavigate();
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        const fetchTeamMembers = async () => {
            try {
                const response = await fetch(`/api/users/get-team-members/${userId}`);
                const data = await response.json();
                setTeamMembers(data);
            } catch (error) {
                console.error('Error fetching team members:', error);
            }
        };

        if (userId) {
            fetchTeamMembers();
        }
    }, [userId]);

    const handleBackLinkClick = () => {
        window.scrollTo(0, 0);
        let portalPath = '/managerportal?tab=your-team'; // Default path for managers

        if (userRole === 'Admin') {
            portalPath = '/adminportal?tab=your-team'; // Path for admin
        } else if (userRole === 'Manager') {
            portalPath = '/managerportal?tab=your-team'; // Path for manager
        }
        navigate(portalPath); // Using navigate instead of window.location.href
    };

    return (
        <div className='team-members-body'>
            <div className="team-members-back-wrapper">
                <BackButton onClick={handleBackLinkClick} />
            </div>
            <h1 className='team-members-header'>Your Team Members</h1>
            <div className="team-members">
                {teamMembers.map(member => (
                    <div key={member.id} className="team-member">
                        <Link to={`/ManagerProgress/${member.id}`}>
                            <h2 className="team-member-name">{member.first_name} {member.last_name}</h2>
                        </Link>
                        <p className="team-member-job-title">{member.job_title}</p>
                        <p className="team-member-email">{member.email}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TeamMembers;