import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../Styles/EmployeeProfile.css';

const EmployeeProfile = () => {
    const { userId } = useParams();
    const [employee, setEmployee] = useState(null);
    const navigate = useNavigate();
    const [goalTextVisible, setGoalTextVisible] = useState([]);

    const handleBackLinkClick = () => {
        navigate('/EmployeeDirectory');
    };

    const handleEditClick = () => {
        navigate(`/EditEmployee/${userId}`);
    };

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const response = await fetch(`/api/users/get-employee-details/${userId}`);
                const data = await response.json();
                setEmployee(data);
            } catch (error) {
                console.error('Error fetching employee details:', error);
            }
        };

        fetchEmployeeDetails();
    }, [userId]);

    const handleGoalTextToggle = (index) => {
        setGoalTextVisible((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = !newVisibility[index];
            return newVisibility;
        });
    };

    if (!employee) return <div>Loading...</div>;

    return (
        <div className="employee-profile">
            <p onClick={handleBackLinkClick} className="back-link">
                Back
            </p>
            <div className="employee-profile-container">
            <div className="employee-card">
                <h1>{employee.user.first_name} {employee.user.last_name}</h1>
                <p>{employee.user.email}</p>
                <div className="employee-card-buttons">
                    <button className="employee-card-edit-link" onClick={handleEditClick}>Edit Employee</button>
                </div>
            </div>
            <div className="employee-info">
                <h3>{employee.user.job_title}</h3>
                <p>{employee.user.job_description}</p>
                <h3>Direct Reports</h3>
                <ul>
                    {employee.directReports.map(report => (
                        <li key={report.id}>{report.first_name} {report.last_name} - {report.job_title}</li>
                    ))}
                </ul>
            </div>
            </div>
            <div className="employee-goals-container">
            <div className="employee-goals">
                <h2>Goals</h2>
                {employee.goals.map((goal, index) => (
                    <div key={goal.id}>
                        <h4>{goal.summary}</h4>
                        {goalTextVisible[index] && (
                            <p>{goal.goal_text}</p>
                        )}
                        <button 
                            className="progress-page-goal-text-toggle-button" 
                            onClick={() => handleGoalTextToggle(index)}
                        >
                            {goalTextVisible[index] ? "Show Less" : "Show More"}
                        </button>
                        <p>Progress: {goal.progress}%</p>
                    </div>
                ))}
                </div>
            </div>
            </div>
    );
};

export default EmployeeProfile;
